import React from 'react';
/**
 * data Columns for grid and mobile view
 * @type {React.FC<Props>}
 * @returns {React.ReactElement} The Data Columns Array
 */
export const DataColumns = ({
  editContentSlider,
  GridFlag,
  BooleanValuesFilterCell,
  MultiViewCalenderFilterCell,
  dateRangeFilter,
  GridDateCell,
  setDateRangeFilter,
}) => {
  const dataColumns = [
    {
      field: 'uc_ossi_af_arg_id',
      title: 'Argument',
      editable: false,
      show: true,
      width: '400px',
      minResizableWidth: 50,
      cell: (props) => (
        <td
          className='edit-link'
          onClick={() => {
            editContentSlider(props.dataItem);
          }}
        >
          {props.dataItem.uc_ossi_af_arg_id}
        </td>
      ),
    },
    {
      field: 'uc_ossi_descr',
      title: 'Description',
      editable: false,
      show: true,
      width: '250px',
      width: '250px',
      minResizableWidth: 50,
    },
    {
      field: 'uc_ossi_data_val',
      title: 'Default Value',
      editable: false,
      show: true,
      width: '200px',
      minResizableWidth: 50,
    },
    {
      field: 'uc_ossi_default_value_cmnt',
      title: 'Default Value Comment',
      editable: false,
      show: true,
      width: '300px',
      minResizableWidth: 50,
    },
    {
      field: 'uc_ossi_example',
      title: 'Example',
      editable: false,
      show: true,
      width: '300px',
      minResizableWidth: 50,
    },
    {
      field: 'uc_ossi_exclude_from_prompt_flg',
      title: 'Exclude from Prompt?',
      editable: false,
      show: true,
      width: '300px',
      minResizableWidth: 50,
      cell: GridFlag,
      filterCell: BooleanValuesFilterCell,
    },
    {
      field: 'uc_ossi_eval_replace_flg',
      title: 'Evaluate Immedietely And Replace Value?',
      editable: false,
      show: true,
      width: '300px',
      minResizableWidth: 50,
      cell: GridFlag,
      filterCell: BooleanValuesFilterCell,
    },
    {
      field: 'ins_user_id',
      title: 'Created By',
      editable: false,
      show: true,
      width: '200px',
      minResizableWidth: 50,
    },
    {
      field: 'ins_dt',
      title: 'Created Date',
      editable: false,
      show: true,
      width: '300px',
      minResizableWidth: 50,
      filter: 'date',
      type: 'dateRange',
      filterCell: (props) =>
        MultiViewCalenderFilterCell({
          ...props,
          dateRangefilter: dateRangeFilter,
          setDateRangeFilter,
          field: 'ins_dt',
        }),
      isRequired: true,
      cell: GridDateCell,
    },
    {
      field: 'last_upd_user_id',
      title: 'Modified By',
      editable: false,
      show: true,
      width: '200px',
      minResizableWidth: 50,
    },
    {
      field: 'last_upd_dt',
      title: 'Modified Date',
      editable: false,
      show: true,
      width: '300px',
      minResizableWidth: 50,
      filter: 'date',
      type: 'dateRange',
      filterCell: (props) =>
        MultiViewCalenderFilterCell({
          ...props,
          dateRangefilter: dateRangeFilter,
          setDateRangeFilter,
          field: 'last_upd_dt',
        }),
      isRequired: true,
      cell: GridDateCell,
    },
  ];

  return dataColumns;
};
export default DataColumns;
